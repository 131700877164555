@import url(./_assets.scss);

@media screen and (max-width:1367px) {
    body{
        .main_container{
            .about_board{
                .about{
                    width: 60%;
                }
            }

            .contact_container{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1281px) {
    body {
        .main_container {
            .container {
                .caption {
                    width: 80%;
                }

                .header{
                    .header_text1 {
                        height: max(100px, 7vh);
                    }
                }
            }

            .about_board {
                .about {
                    width: 65%;
                }
            }

            .contact_container {
                width: 100%;

                .form_details{
                    width: 60%;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    body {
        .main_menu {
            .toggle_menu {
                width: min(60em, 80%);
            }
        }

        .contact_overlay{
            .contact_submit{
                width: min(700px, 90%);

                .img{
                    display: none;
                }
            }
        }

        .main_container {
            .container {
                .header {
                    .header_text1 {
                        height: max(80px, 5vh);
                    }
                }

                .caption {
                    width: 90%;
                }
            }

            .about_board {
                padding: 80px 0px;

                .about {
                    width: 90%;

                    .other_details {
                        .mission {
                            width: 50%;
                        }
                    }
                }

                .about_image {
                    display: none;
                }
            }

            .services {
                gap: 10px;

                .serve_trey{
                    .serv_2 {
                        .serv_flex2 {
                            width: 60%;
                        }
    
                        .serv_flex3 {
                            display: none;
                        }
                    }
                }

                
            }

            .feedback_container{
                flex-direction: column;
                margin-top: .6rem;
                height: 70vh;

                .feedback_image{
                    height: 60vh;
                }

                .feedback_image,.feedback_content{
                    width: 100%;

                    img{
                        width: 100%;
                        object-position: top;
                    }
                   
                }

                .feedback_content{
                    
                    .content{
                        width: 80%;
                        margin: auto;
                        padding: 50px 0;

                        button{
                            padding-top: 20px;
                        }

                    }
                }
            }

            .contact_container {
                .form_details {
                    width: 60%;
                }
            }

            .call_details {
                width: 90%;

                .other_info {
                    font-size: clamp(0.8rem, 1.3vw, 1.3rem);
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    $primary_color: #006a5f;
    $background_color: #fffef8;
    $secondary_color: #707070;

    body {

        // Splash Screen styling here...
        .splash_screen {
            z-index: 11;

            .splash_element {
                img {
                    width: 25%;
                }
            }
        }

        .whatsapp{
           
            width: 50px;
            right: 3%;
            bottom: 5%;
            transform: translate(-3%,-5%);

            img{
                width: 100%;
            }
    
            span{
                position: absolute;
                width: max-content;
                right: 45%;
                top: 50%;
                transform: translate(-45%,-50%);
                background-color: white;
                padding: 10px;
                opacity: 0;
                transition: .5s;
            }
        }

        .main_menu {
            .close_menu {
                width: 30px;
                right: 8%;
            }

            .toggle_menu {
                ul {
                    line-height: 1.3;

                    li {
                        border-bottom: 1px solid white;
                    }
                }

                .contact_info {
                    display: block;

                    .social_media {
                        width: 55%;

                        ul {
                            justify-content: space-between;
                        }
                    }
                }
            }
        }

        .main_container {

            .socials_container{
                height: max(400px,50dvh);
                .image_tray{
                   height: auto;
                }
            }

            .socials {
                margin: 40px 0;

                p {
                    margin: 0 auto;
                }

                
            }

            .container {
                .nav_bar {
                    padding-top: 35px;
                }

                .header {
                    margin: 50px auto;

                    .header_text1 {
                        margin-bottom: 0;
                        height: min(90px, 13vh);
                    }
                }

                .caption {
                    padding-top: 50px;
                    width: 90%;
                    color: rgb(78, 78, 78);
                    opacity: 1;

                    .caption_text {
                        font-size: 0.9rem;
                        opacity: 1;
                        transform: translateY(0);
                    }

                    .caption_button {
                        svg {
                            display: none;
                        }

                        .svg_image {
                            img {
                                display: none;
                            }

                            .svg_text {
                                padding: 20px;
                                border: 2px solid $primary_color;
                                border-radius: 10px;
                                font-size: 0.9rem;
                                width: 80%;
                            }
                        }
                    }
                }

                .banner_container {
                    height: 65dvh;

                    .banner {
                        video {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            display: block;
                        }
                    }
                }
            }

            .corousel {
                display: block;
                width: 100%;
                overflow: hidden;
                margin-top: 160px;

                .corousel_trey {
                    animation: scroll 185s linear infinite;
                }

                .corousel_image1 {
                    width: 100%;
                }

                .corousel_image2 {
                    width: 100%;
                }

                .corousel_image3 {
                    width: 100%;
                }

                .corousel_image4 {
                    display: none;
                }
            }

            .services {
                .serv_1 {
                    .services_header {
                        margin: 60px auto;
                        margin-bottom: 40px;

                        .services_text {
                            width: 95%;
                            line-height: 2;
                        }
                    }
                }

                .serve_trey{
                    .serv_2 {
                        margin-bottom: 0;
                        padding-bottom: 60px;
    
                        .serv_flex1 {
                            width: 100%;
                            margin-right: 0;
    
                            .service_list {
                                .text1 {
                                    font-size: 1rem !important;
                                    font-family: "Montserrat", sans-serif !important;
                                    font-weight: bold;
                                }
                            }
    
                            .name_text {
                                img {
                                    width: 70%;
                                }
                            }
    
                            .reserve {
    
                                .text1 {
                                    font-family: "Montserrat", sans-serif;
                                }
                            }
                        }
    
                        .serv_flex2 {
                            display: none;
                        }
    
                        .serv_flex3 {
                            display: none;
    
                            .image1 {
                                display: none;
                            }
    
                            .image2 {
                                display: none;
                            }
                        }
                    }
                }

                
            }

            .about_board {
                display: block;
                margin: 0;
                padding: 60px 0;

                .about {
                    width: 80%;
                    margin: 0 auto;

                    .about_details {
                        width: 100%;

                        .about_us {
                            width: 100%;

                            .about_text {
                                width: 100%;
                                line-height: 2.3;
                            }

                            p {
                                font-size: clamp(3rem, 3vw, 3rem);
                            }
                        }
                    }

                    .other_details {
                        display: block;
                        width: 100%;
                        margin-top: 3rem;

                        .mission {
                            width: 100%;

                            .mission_text {
                                width: inherit;
                                line-height: 2;
                            }
                        }

                        .vision {
                            width: 100%;

                            .vision_text {
                                width: inherit;
                                line-height: 2;
                            }
                        }
                    }
                }

                .about_image {
                    display: none;
                }
            }

            .socials_container {
                margin-bottom: 0;

                .image_tray {
                    .insta_logo {
                        img {
                            width: 100%;
                        }
                    }

                    .tiktok_logo {
                        top: 80%;
                        right: 11.5%;
                        transform: translate(-11.5%, -80%) scale(1);
                    }

                    .flex1 {
                        width: 60%;
                    }

                    .flex2 {
                        width: 40%;

                        .social_overlay {
                            width: 100%;
                            height: 100%;
                            background-color: $primary_color;
                            mix-blend-mode: multiply;
                            display: grid;
                            place-content: center;

                            a {
                                img {
                                    width: 70px;
                                    display: block !important;
                                }
                            }
                        }
                    }

                    .flex3 {
                        display: none;
                    }

                    .flex4 {
                        display: none;
                    }
                }
            }

            .contact_container {
                width: 100%;
                margin-top: 40px;
                margin-bottom: 50px;

                .form_details {
                    width: 90%;

                    .details {
                        .initials {
                            display: block;

                            .name_details {
                                margin-bottom: 2rem;
                                width: 100%;

                                input {
                                    width: 100%;
                                }
                            }

                            .email {
                                width: 100%;
                            }
                        }
                    }

                    .message {
                        .message_text textarea {
                            height: 150px;
                        }
                    }

                    .submit_button {
                        width: 350px;

                        img {
                            width: 50%;
                        }

                        .submit_text {
                            left: 18%;
                            transform: translate(-18%, -50%);
                        }
                    }
                }
            }

            .call_details {
                .contact_form {
                    .up_button {
                        display: none;
                    }
                }

                .other_info {
                    display: block;

                    .info {
                        margin-bottom: 8rem;
                    }

                    .privace_text {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:400px) {
    body{
        .main_container{
            #code_holder{
                font-size: .8rem !important;
            }
        }
    }
}